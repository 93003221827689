<template>
  <module-container
    route-name="pricelists"
    show-toolbar
    :title="$t('pricelists')"
  >
    <template #route>
      <router-view />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component
export default class PricelistsIndex extends Mixins(PaginateProviderMixin) {}
</script>
